//
// backgrounds.scss
//

@each $color, $value in $theme-colors {
  .bg-#{$color} {
    &.bg-soft {
      background-color: rgba(($value), 0.25) !important;
    }
    &.bg-soft2 {
      background-color: rgba(($value), 0.15) !important;
    }
  }
}
