//
// breadcrumb.scss
//

// Breadcrumb item arrow
.breadcrumb-item {
  /*> a {
        color: $gray-700;
    }*/
  + .breadcrumb-item {
    &::before {
      font-family: "Material Design Icons", sans-serif;
    }
  }
}

// body[theme="dark"] {
//     .breadcrumb-item {
//         > a {
//             color: $gray-300;
//         }
//     }
// }
