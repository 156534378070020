.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  text-align: center;
}

.google-map {
  width: 100%;
  height: 100%;
  min-height: 100px;
  /* height: 60vh; */
}

.pin {
  display: flex;
  align-items: center;
  width: 180px;
  color: var(--main-blue);
}

.pin-icon {
  font-size: 4rem;
}

.pin-text {
  font-size: 1.3em;
}

@media screen and (min-width: 1200px) {

  .map-h2 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .pin {
    width: 15vw;
  }

  .pin-icon {
    font-size: 10vw;
  }
}
