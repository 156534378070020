//
// Widgets.scss
//
.mini-stats-container {
  display: flex;
  justify-content: flex-start;
}
.mini-stats-wid {
  width: 20%;
  margin-right: 10px;
  .mini-stat-icon {
    overflow: hidden;
    position: relative;
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 8px;
      height: 54px;
      background-color: rgba($white, 0.1);
      left: 16px;
      transform: rotate(32deg);
      top: -5px;
      transition: all 0.4s;
    }

    &::after {
      left: -12px;
      width: 12px;
      transition: all 0.2s;
    }
  }

  &:hover {
    .mini-stat-icon {
      &::after {
        left: 60px;
      }
    }
  }
}
@media (max-width: 1113px) {
  .mini-stats-wid.last {
    width: 100% !important;
    margin-right: 0;
    margin-bottom: 23px !important;
  }
  .mini-stats-wid {
    width: 100% !important;
    margin-right: 0;
    margin-bottom: 5px;
  }
  .mini-stats-container {
    display: block;
  }
}
@media (max-width: 1700px) {
  .mini-stats-wid.last {
    width: 28%;
  }
}
.medium-stats-wid {
  .medium-stat-icon {
    overflow: hidden;
    position: relative;
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 5px;
      height: 80px;
      background-color: rgba($white, 0.1);
      left: 16px;
      transform: rotate(32deg);
      top: -5px;
      transition: all 0.4s;
    }

    &::after {
      left: -12px;
      width: 12px;
      transition: all 0.2s;
    }
  }

  &:hover {
    .mini-stat-icon {
      &::after {
        left: 100px;
      }
    }
  }
}
