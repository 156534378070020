/* TODO MODIFY AS NEEDED */

.confirm,
.assign,
.pending,
.htmlCss,
.js2,
.js3,
.nodejs,
.mySql,
.react {
  color: #fff;
}

.confirm {
  /* background-color: #00a0d6d8 !important;42b6f8 */
  background-color: #3aa1aa !important;
  /* color: #000000 !important; */

  color: #ffffff !important;
  border: 1px solid #3aa1aa !important; /* //cce2cb #bbd6ac 69a1a3 039f9b*/
  border-radius: 5px !important;
}

.assign {
  background-color: #ea716a !important;
  /* color: #000000 !important; */
  border: 2px solid #ea716a !important;
  border-radius: 5px !important;
}

.pending {
  background-color: #ea716a !important;
  border: 1px solid #ea716a !important;
  /* color: #000000 !important; */
  border: none !important;
  border-radius: 5px !important;
}

.moved {
  background-color: #3aa1aa !important;
  /* color: #000000 !important; */
  border: 2px solid #ea716a !important;
  border-radius: 5px !important;
}

.htmlCss {
  background-color: #429970 !important;
}

.js1 {
  background-color: #ffdb5c !important;
  color: #000;
}

.js2 {
  background-color: #fa6e59 !important;
}

.js3 {
  background-color: #be59fa !important;
}

.nodejs {
  background-color: #4897d8 !important;
}

.mySql {
  background-color: #6d6d6d !important;
}

.react {
  background-color: #59e5fa !important;
}
