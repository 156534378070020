/* Should prevent ResizeObserver errors, error shoud go away when version is updated */
.resize-observer {
  display: none !important;
}
/**/

.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}

//HOX CUSTOM FOR SEARCH BUTTON
.search-button {
  margin-top: 50px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

// Custom list on hover background

.location-list {
  // background-color: #f4511e;
  // border: none;
  // color: white;
  // padding: 16px 32px;
  // text-align: center;
  // font-size: 16px;
  // margin: 4px 2px;
  // opacity: 0.6;
  transition: 0.3s;
}

.location-list:hover {
  // opacity: 1;
  background-color: #f1f1f1;
}

.dashboard-card-1 {
  min-height: 380px;
}

.dashboard-card-2 {
  min-height: 300px;
  max-height: 300px;
}

.dashboard-card-3 {
  min-height: 300px;
  max-height: 300px;
  // min-height: 260px;
  // max-height: 261px;
}

.dashboard-journal {
  max-height: 220px;
}

.box-flex {
  display: flex;
  height: 100%;
  .child {
    display: inline-block;
    align-self: flex-end;
  }
}

.align-bottom {
  position: absolute;
  bottom: 0;
}

.modal-footer-no-margin {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

// Vertical line
.vl-right {
  border-left: 0.5px solid #e8edf3;
  height: 100%;
  position: absolute;
  right: 100%;
  // margin-left: 0px;
  top: 0;
}
.display-7 {
  font-size: 2em;
}
.vl {
  overflow: hidden;
  border-left: 0.5px solid #e8edf3;
  height: 120%;
  margin-top: -20px;
  position: absolute;
  left: 100%;
  margin-left: 0px;
  top: 0;
}
.vl-appointments {
  border-left: 0.5px solid #e8edf3;
  height: 120%;
  margin-top: -20px;
  position: absolute;
  left: 100%;
  margin-left: 0px;
  top: 0;
}
.appointments-container {
  overflow: hidden;
}
.vl-dark {
  border-right: 0.5px solid #909090;
  height: 100%;
  position: absolute;
  left: 100%;
  margin-left: 0;
  top: 0;
}

// customer-profile card info
#window-card {
  display: flex;
  justify-content: space-evenly;
}

// Vertical line
.round-btn {
  border-radius: 100px;
}

// show hr when media view
.hidden-media {
  display: none;
}
@media (max-width: 576px) {
  .hidden-media {
    display: inline-block;
  }
  #window-card {
    justify-content: flex-start;
  }
  #window-card .window-child {
    padding: 20px 50px 10px 10px;
  }
}

#tags-tree-management {
  width: 300px;
  margin-right: 10px;
}
@media (max-width: 576px) {
  #tags-tree-management {
    width: 100%;
    margin-right: 0px;
  }
}
// show hr when media view
.hidden-media-norm {
  display: inline-block;
}
@media (max-width: 576px) {
  .hidden-media-norm {
    display: none;
  }
}

// show "Page" text in dashboard
.hidden-text-dashboard {
  display: inline-block;
}
@media screen and (max-width: 768px) and (min-width: 988px) {
  .hidden-text-dashboard {
    display: none;
  }
}

.card-no-padding-y {
  padding: 0;
  margin-top: 0px;
  margin-bottom: 0px;
}

.card-nothing {
  width: 600px;
}
.download-btn-bg {
  height: 35px;
  width: 35px;
  line-height: 35px;
  box-shadow: none;
  padding: 0;
  font-size: 16px;
  // margin-top: 10px;
  color: #ffffff;
  background-color: #e1e1e1;
  border-radius: 50%;
}
.download-btn {
  height: 30px;
  width: 30px;
  line-height: 30px;
  box-shadow: none;
  padding: 0;
  font-size: 16px;
  background-color: #32394e17;
  border-radius: 50%;
}
.profile-dropdown {
  padding: 0;
  background-color: #ececec;
  color: #ececec;
}
.profile-btn {
  // height: 29px;
  // width: 30px;
  border: 0;
  width: auto;
  line-height: 30px;
  box-shadow: none;
  // padding: 0;
  padding: 0px 20px 0px 20px;
  background-color: #00a0d6;
  border-radius: 30px;
}
.clear-btn {
  color: #ffffff;
  border: 0;
  background-color: #eeeef500;
}
.clear-btn-del {
  color: #ffffff;
  border: 0;
  background-color: #eeeef500;
  min-width: fit-content;
  min-height: fit-content;
  overflow: visible;
}
.clear-btn-delete {
  margin-right: -15px;
  box-shadow: none;
  background-color: #ffffff8f;
  color: black;
  border-radius: 10px;
  transition: 0.3s;
}

.clear-btn-delete:hover {
  // opacity: 1;
  color: #c36458;
  background-color: #fbeaea;
}
.transition-color-link {
  font-weight: 400;
  color: rgba(40, 40, 40, 0.867);
  transform: 0.3s;
}
.transition-color-link:hover {
  color: #45b9dd;
}
.box {
  display: flex;
  align-items: center;
  // justify-content: center;
  justify-content: flex-start;
}

.box-card {
  min-height: 180px;
  overflow-y: visible;
}
@media (max-width: 576px) {
  .box-card {
    min-height: auto;
  }
}
.box-center {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
}

.progress-div {
  // display: flex;
  // justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}
.progress-box {
  width: 300px;
  // display: inline;
  margin: 0 auto;
}

.bolder-font {
  font-weight: 600;
}
.custon-bar-bg {
  background: #7cb342;
  // background-color: #b01414;
  color: #45b9dd;
}

.center-div {
  // display: flex;
  // justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}
.center-card {
  max-width: 600px;
  // display: inline;
  margin: 0 auto;
}

.modalInfo p,
.modalInfo span {
  margin-bottom: 0;
}

.group-cards {
  height: 200px;
  transition-duration: 0.2s;
}
.group-modal-info {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  transition-duration: 0.2s;
  margin-bottom: 0;
}

.group-modal-info small {
  margin-left: auto;
  visibility: hidden;
}

.group-modal-info:hover small {
  visibility: visible;
}

.group-modal-info:hover {
  color: #00a0d6;
}

.group-cards:hover {
  transform: scale(1.01);
  cursor: pointer;
  box-shadow: 1px 1px 1px 1px #bababa6e;
  color: #00a0d6;
}

.icon-group {
  margin-top: -3px;
  margin-bottom: 0;
  padding: 0;
  height: 150px;
  width: 235px;
  background-color: #ffffff00;
  border-radius: 5px;
}
@media (min-height: 900px) {
  .icon-group {
    margin-top: -22px;
    margin-bottom: 0;
    padding: 0;
  }
}

.icon-button {
  color: white;
  background-color: #00a0d6;
}

// .offset-right {
//   // offset-position: left;
//   // margin-left: -50%;
//   // background-color: #00a0d6;
// }
.ant-drawer-body {
  background-color: #f8f8f8;
}
.ant-select-selection-item {
  font-weight: 400;
}

// Antd small select matching reactstrap input size
/*.ant-select-sm.ant-select-single {
  line-height: 25px !important;

  .ant-select-selector {
    height: 27px !important;

    .ant-select-selection-placeholder {
      line-height: 25px !important;
    }
  }

  .ant-select-selection-item {
    line-height: 25px !important;
  }
}*/

// Customer management nav custom
#navigation-management ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#navigation-management {
  display: inline-block;
  // position: absolute;
  // z-index: 100;
  // display: inline-flex;
  // justify-content: center;
  .navbar-nav {
    display: inline-block;
    font-weight: 500;
    .nav-item {
      .nav-link.active {
        color: #0096c8;
        // border-bottom: solid 2px #00a0d6;
        // border: solid 1px #00a0d6;
        border-radius: 25px;
        background-color: #d7f1fb7d;
      }
    }
    .nav-item {
      width: fit-content;
      display: inline-block;
      margin: 0rem 1rem 0.5rem 0rem;
      .dropdown-menu {
        width: 400px;
        animation-name: DropDownSlide;
        animation-duration: 0.3s;
        animation-fill-mode: both;
        margin: 0;
        position: absolute;
        z-index: 1000;

        &.show {
          top: 100% !important;
        }
        .dropdown-item {
          color: #595959;
          &.active,
          &:hover {
            color: #009bce;
          }
        }
      }
    }
    .nav-link {
      font-size: 14px;
      padding: 3px 12px;
      color: #525252;
      a {
        position: fixed;
      }
      i {
        font-size: 15px;
      }
      &:focus,
      &:hover {
        color: #00a0d6;
        background-color: transparent;
      }
    }

    > .dropdown-toggle {
      &.active {
        > a {
          color: #00a0d6;
          background-color: transparent;
        }
      }
    }

    // .dropdown-item {
    //   position: relative;
    //   background-color: transparent;

    //   &.active,
    //   &:active {
    //     color: #00a0d6;
    //   }
    // }
  }
}

// welcome card picture
.container-img {
  width: 100%;
  height: 100%;
  position: relative;

  img {
    width: 180px;
    height: auto;
  }
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

// Turn arrow
i.menu-arrow-down {
  transform: rotate(0deg);
  transition: 1s;
}

i.menu-arrow-down.up {
  transform: rotate(180deg);
  transition: 1s;
}

#input-eye-btn {
  cursor: auto;
  width: 41px;
  border-radius: 0px 5px 5px 0px;
  border: solid 1px #9d9d9d6b;
}

// Range Picker
.range-picker {
  width: 100%;
}

// Select client dropdown
.select-client-dropdown {
  font-weight: 600 !important;
  width: 100%;
  min-width: 300px;
  max-width: 350px;
}

// Table top scroll
.table-scroll-window {
  max-height: 75vh;
}

// search-field
.search-field {
  text-align: end;
}
@media (max-width: 576px) {
  .search-field {
    width: 100%;
    text-align: start;
  }
  .search-field .box {
    width: 100%;
  }
}
// Sticky card
.sticky-wrapper {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  border: 2px solid;
  min-height: 1000px;
}
.sticky-card {
  height: 500px;
  background: #aaa;
  border: 1px solid red;
  position: -webkit-sticky;
  position: sticky !important;
  bottom: 0 !important;
}

.wrapper {
  width: 100%;
  max-height: 70vh;
  overflow: auto;
}

.parent {
  height: 100%;
  margin: 0% 0;
}

.sticky {
  width: 100%;
  position: sticky;
  display: inline-block;
  margin: auto;
  // top: 20px;
  // background: red;
}
.sub-label-select div {
  color: #8b8b8b !important;
  margin-top: 0;
  text-overflow: ellipsis !important;
  width: 310px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
}

// TEST
.modified {
  border-color: #c36458;
}
.saved {
  border-color: #7cb342;
}

// Role dropdown
#user-role-dropdown {
  cursor: pointer;
  transition: 0.4s;
  .role {
    padding: 5px;
    // font-size: 0.9em;
  }
  .role:hover {
    color: #00a0d6;
  }
  .roles {
    display: none;
    height: 0;
    // padding-left: 5px;
  }
  &.active {
    .float-end {
      i {
        transform: rotate(-180deg);
      }
    }
    .roles {
      display: block;
      height: auto;
    }
  }
}
/* Custom radio buttons */
.ant-radio-wrapper span {
  font-size: 12px;
}
// calendar
.fc-button-primary {
  color: #e1e1e1;
  background-color: #009bce;
}

// custom li checklist -style
#custom-list {
  list-style: none;
  margin-left: -10px;
  .uncheched {
    list-style: "\2715";
    color: gray;
  }
  .cheched {
    list-style: "\2713";
    color: #34c38f;
  }
  span {
    margin-left: 10px;
  }
}
#custom-list-profile {
  list-style: none;
  .uncheched {
    list-style: "\2715";
    color: gray;
  }
  .cheched {
    list-style: "\2713";
    // color: gray;
  }
  ul {
    margin-bottom: 0 !important;
  }
  span {
    margin-left: 10px;
  }
}

@media (max-width: 750px) {
  .ant-drawer.custom {
    .ant-drawer-content-wrapper {
      width: 450px !important;
    }
  }
}

@media (min-width: 751px) {
  .ant-drawer.custom {
    .ant-drawer-content-wrapper {
      width: 700px !important;
    }
  }
}

.ant-table.ant-table-middle {
  font-size: 13px !important;
}

// .ant-table-cell .extra-table-row-background {
//   // background-color: #d2d2d225;
//   // padding: 15px;
//   // padding: 0;
// }

.narrow-header {
  .ant-table-thead > tr > th {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
}

.ant-table-thead > tr > th {
  font-weight: 500 !important;
}

// AntdTable titles
.ant-table-column-title {
  font-weight: 500 !important;
}

.ant-table-cell-ellipsis {
  font-size: 13px !important;
}

// Used in reporting (lighter table)
.extra-padding {
  tr.ant-table-expanded-row:hover > td,
  tr.ant-table-expanded-row > td {
    padding: 15px !important;
  }
  .ant-table-cell {
    padding: 12px 12px 12px 12px !important;
  }
  .with-extra-row .ant-table-cell {
    padding: 12px 12px 12px 5px !important;
  }
}
tr.ant-table-expanded-row:hover > td,
tr.ant-table-expanded-row > td {
  background-color: #c9c9c929 !important;
}

// Timeline custom
// .react-calendar-timeline .rct-dateHeader {
//   // display: flex;
//   // align-items: center;
//   // justify-content: center;
//   height: 100%;
//   /* border-bottom: 1px solid #bbb; */
//   cursor: auto !important;
//   // font-size: 14px;
//   font-weight: 500;
//   // background-color: #fbfbfb !important;
//   border: 1px solid rgba(187, 187, 187, 0.087) !important;
//   // height: 40px;
//   padding: 24px;
// }
.react-calendar-timeline .rct-outer .rct-scroll {
  cursor: default !important;
}

#timeline-container {
  border: 1px solid rgba(106, 106, 106, 0) !important;
  border-radius: 3px;
  overflow: hidden;
}

// For timeline calendar
.disable-scroll {
  overflow: hidden;
}
.custom-timeline {
  .ant-slider-mark {
    font-size: 12px;
  }

  .ant-slider-mark-text {
    margin-top: -35px;
  }
  .ant-slider-with-marks {
    margin-bottom: 0px;
  }

  .ant-slider-handle {
    // position: absolute;
    width: 10px;
    height: 10px;
    margin-top: -4px;
    // background-color: #fff;
    // border: 2px solid #91d5ff;
    // border-radius: 50%;
    // box-shadow: 0;
    // cursor: pointer;
    // transition: border-color .3s,box-shadow .6s,transform .3s cubic-bezier(.18,.89,.32,1.28);
  }
}
// Customer management
.container-select-customer {
  display: flex;
  justify-content: flex-start;
  padding-top: 5px;
  padding-bottom: 5px;
  overflow: hidden;
  .flex-1 {
    width: auto;
  }
  .flex-2 {
    width: auto;
    .input-field {
      width: 350px;
      padding-top: 1px;
    }
  }
  .flex-3 {
    padding-top: 5.5px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(
      .ant-select-customize-input
    )
    .ant-select-selection-search-input {
    margin-top: 0px;
  }
}

// Events title groupcall
.container-event-title {
  display: flex;
  flex-wrap: wrap;
  .flex-1 {
    flex-grow: 0;
  }
  .flex-2 {
    flex-grow: 1;
    text-align: center;
  }
}
.datepicker-events {
  width: 105px;
  .ant-picker-input > input {
    font-size: 16px;
    background-color: transparent;
  }
}
.datepicker-events-range {
  text-align: center;
  width: 200px;
  .ant-picker-input > input {
    font-size: 16px;
    background-color: transparent;
  }
}
.datepicker-events-btn-1 {
  margin-right: 5px;
  i {
    font-size: 14px;
  }
}
.datepicker-events-btn-2 {
  margin-left: 5px;
  i {
    font-size: 14px;
  }
}
// Events title groupcall END

// Instuctions
.instructions {
  p,
  ul,
  li {
    font-size: 13px;
  }
  .ant-collapse-expand-icon {
    font-size: 11.5px;
  }
  .ant-collapse-borderless > .ant-collapse-item,
  ant-collapse-item ant-collapse-item-active,
  .ant-collapse-borderless > .ant-collapse-item:last-child {
    margin-bottom: 10px;
    border: none;
    background-color: rgba(208, 208, 208, 0.11);
    // background-color: rgba(208, 208, 208, 0.36);
    border-radius: 7px;
  }
  .ant-collapse-content-box {
    border-top: 1px solid #4d4d4d17;
  }
  .ant-collapse-borderless
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding-top: 15px;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-header-text {
    font-weight: 500;
  }
}

// ANT TABLE
/* Table header modifications */
.custom-table-header-width
  .ant-table-thead
  > tr
  > th.ant-table-cell:not(:first-child) {
  width: 125px;
}
.custom-table-header-width
  .ant-table-thead
  > tr
  > th.ant-table-cell.ant-table-selection-column {
  width: 20px !important;
}

// Custom nav pill tabs
.custom-nav-pills.nav-tabs > li > a,
.custom-nav-pills.nav-pills > li > a {
  font-weight: 400;
}

// EMAIL BULLETINS CUSTOM START
.email-bulletins-col-1 {
  width: 20% !important;
  padding-inline: 20px !important;
}
.email-bulletins-col-2 {
  width: 80% !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.d-flex-email {
  display: flex !important;
}
@media (max-width: 768px) {
  .email-bulletins-col-1 {
    width: 100% !important;
  }
  .email-bulletins-col-2 {
    width: 100% !important;
    margin-top: 15px !important;
  }
  .bulletin-new-btn {
    width: 100%;
  }
  .d-flex-email {
    display: inline !important;
  }
}
.table-btn-subs {
  padding-left: 20px !important;
}
.table-container-subs {
  padding: 5px;
}

// EMAIL BULLETINS CUSTOM END

// Remove the default arrow from "details"
details.remove-arrow > summary {
  list-style: none;
}

// Custom scrollbar
.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent; // Hide the track
  }

  &::-webkit-scrollbar-thumb {
    background: #8a8a8a;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: #a1a1a1;
    }
  }
}
