//
// _card.scss
//

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;
  border: $card-border-width solid $card-border-color;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;
}

// body[theme="dark"] {
//   .card {
//     background-color: $card-bg-dark;
//   }

//   .card-title {
//     color: $gray-500;
//   }

//   .border-bottom {
//     border-bottom: 1px solid $gray-700 !important;
//   }
// }
