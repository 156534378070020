/* @import url("https://fonts.googleapis.com/css?family=Work+Sans");

body {
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  background: #fafafa;
} */
.rct-item-content {
  border: dotted 1px deepskyblue;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  // border-bottom: 1px solid var(--#{$prefix}gray-350) !important;
  background-color: #ffffff00;
  line-height: 75px !important;
  // border-bottom: 1px solid var(--#{$prefix}gray-350) !important;
  // border-bottom: none;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  // background: var(--#{$prefix}gray-100) !important;
  // border-bottom: none;
  background-color: #ffffff00;
  border-bottom: 1px solid var(--#{$prefix}gray-350) !important;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
  // background: var(--#{$prefix}gray-100) !important;
  background-color: #ffffff00;
  border-bottom: 1px solid var(--#{$prefix}gray-350) !important;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
  background-color: #ffffff00;
  border-bottom: 1px solid var(--#{$prefix}gray-350) !important;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  border-bottom: none;
  border-bottom: 1px solid var(--#{$prefix}gray-350) !important;
  // padding-right: 5px;
  // border-right: 1px solid var(--#{$prefix}gray-350) !important;
  // border-right: 0;
  // box-shadow: 5px 0 10px 0px var(--#{$prefix}gray-350) !important;

  border-bottom: 1px solid var(--bs-gray-600) !important;
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.react-calendar-timeline .rct-header .rct-label {
  border-bottom: none;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
  border-color: #ccc;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
  border-left: 1px solid var(--#{$prefix}gray-350) !important;
  margin-left: -1px;
}

.react-calendar-timeline .rct-header .rct-label-group {
  /* font-family: "Work Sans", sans-serif; */
  background: #eeeeeedb !important;
  color: #000;
  font-size: 1rem;
}

.react-calendar-timeline .rct-sidebar-header {
  background: #eee;
  color: var(--#{$prefix}gray-900) !important;
  text-align: center;
  padding-top: 1rem;
}

.react-calendar-timeline .rct-header-root {
  // background-color: var(--#{$prefix}gray-100) !important;

  background-color: transparent;
  color: var(--#{$prefix}gray-700);
  margin-top: -5px;
  font-weight: 500;
  font-size: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.094) !important;

  .custom-header {
    padding: 0 10px;
    color: var(--#{$prefix}gray-900) !important;
    font-size: 14px;
    font-weight: 500;
    margin-top: 35px;
  }
}
.rct-sidebar {
  width: 151px !important;
}
.rct-scroll {
  margin-right: -2px !important;
}

.react-calendar-timeline .rct-dateHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  //cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0) !important;
  color: var(--#{$prefix}gray-700);
  border-right: 1px solid var(--#{$prefix}gray-350);
  border-left: 1px solid var(--#{$prefix}gray-350);
  border-bottom: none;
  cursor: auto !important;
  height: 100%;
  padding: 24px;
  padding-right: 2px;
  padding-left: 2px;
  z-index: 1;
}
.react-calendar-timeline .rct-dateHeader-primary {
  background-color: rgba(187, 187, 187, 0) !important;
  border: none;
  color: var(--#{$prefix}gray-500);
}
.react-calendar-timeline .rct-sidebar {
  overflow: visible;
  border-right: 1px solid var(--#{$prefix}gray-200) !important;
  z-index: 1;
  border-left: 0;
  background-color: #ffffff;
  box-shadow: 4px 0 10px 0px rgba(39, 39, 39, 0.06);
}

.react-calendar-timeline .rct-calendar-header {
  background-color: rgba(187, 187, 187, 0) !important;
  margin-top: 5px;
  border: 1px 0px 1px 0px solid var(--#{$prefix}gray-350) !important;
  font-size: 14px;
}

.react-calendar-timeline .rct-sidebar.rct-sidebar-right {
  border-left: 1px solid var(--#{$prefix}gray-200) !important;
  box-shadow: -5px 0 10px 0px var(--#{$prefix}gray-300) !important;
  margin-left: 1px;
  z-index: 1;
}

// theme dark
[data-layout-mode="dark"] {
  .react-calendar-timeline .rct-sidebar {
    border-right: 1px solid var(--#{$prefix}gray-300) !important;
    box-shadow: 5px 0 10px 0px var(--#{$prefix}gray-300) !important;
    background-color: var(--#{$prefix}gray-200);
  }
  .react-calendar-timeline .rct-calendar-header {
    border: 1px 0px 1px 0px solid var(--#{$prefix}gray-900) !important;
  }
  .ant-slider-mark-text {
    color: var(--#{$prefix}gray-500);
  }
  .ant-slider-rail {
    background-color: #ffffff3d;
  }
}
