//
// _dropdown.scss
//

.dropdown-menu {
  box-shadow: $box-shadow-lg;
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  margin: 0;
  position: absolute;
  z-index: 1000;

  &.show {
    top: 100% !important;
  }
}

.dropdown-menu-end[style] {
  left: auto !important;
  right: 0 !important;
}

.dropdown-menu[data-popper-placement^="right"],
.dropdown-menu[data-popper-placement^="top"],
.dropdown-menu[data-popper-placement^="left"] {
  top: auto !important;
  animation: none !important;
}

@keyframes DropDownSlide {
  100% {
    transform: translateY(0);
  }
  0% {
    transform: translateY(10px);
  }
}

@media (min-width: 600px) {
  .dropdown-menu-lg {
    width: 320px;
  }

  .dropdown-menu-md {
    width: 240px;
  }
}

.dropdown-divider {
  border-top-color: $border-color;
}

// Dropdown Mega Menu

.dropdown-mega {
  position: static !important;
}

.dropdown-megamenu[style] {
  padding: 20px;
  left: 50px !important;
  right: 20px !important;
  width: 50%;
  margin-left: 10%;
  min-width: fit-content;
}

.management-item {
  height: auto;
  box-shadow: none !important;
  color: rgba($sidebar-menu-sub-item-color, 0.9);
  border: 0;
  border-radius: 0px;
  position: relative;

  &:hover {
    color: rgba($primary, 0.9);
  }
}
.card-management {
  margin-top: -10px;
  width: auto;
  margin-bottom: 0px;
  border-color: rgba(255, 255, 255, 0);
  color: rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0);
  margin-left: 0px;
  margin-right: 0px;
  box-shadow: 0 0.75rem 1.5rem rgba(23, 35, 50, 0);
}
.card-management2 {
  margin-top: -10px;
  width: auto;
  margin-bottom: 0px;
  border-color: rgba(255, 255, 255, 0);
  color: rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0);
  margin-left: 0px;
  margin-right: 0px;
  box-shadow: 0 0.75rem 1.5rem rgba(23, 35, 50, 0);
}

.light-font {
  font-weight: 300;
}

.management-sz1 {
  width: 50%;
  justify-content: flex-start;
}
.management-sz2 {
  width: 50%;
  justify-content: flex-end;
}
.sz2 {
  display: flex;
  width: 50%;
  min-width: 80px;
}
@media screen and (max-width: 1536px) and (min-width: 988px) {
  .sz2 .management-sz1 .management-sz2 {
    width: 100%;
    display: block;
    // justify-content: flex-start;
  }
}

.choose {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
}

// Dropdown size

.dropdown-mega-menu-xl {
  width: 20rem;
}

.dropdown-mega-menu-lg {
  width: 26rem;
}

// Ant select
//CUSTOM
#heavy .ant-select-selection-item {
  font-weight: 500;
  font-size: large;
  letter-spacing: 0.02rem;
  color: $gray-700;
  // font-family: sans-serif;
}
#heavy .ant-select-selection-placeholder {
  font-weight: 500;
  font-size: large;
  letter-spacing: 0.02rem;
  color: $gray-700;
  // font-family: sans-serif;
}

//CUSTOM 

.ant-select-selection-placeholder,
.ant-select-arrow {
  z-index: 1;
}

.ant-select-selector {
  color: $gray-700 !important;
  // border: 1px solid $gray-400 !important;
  // border-radius: 0.25rem !important;
}

.ant-picker {
  color: $gray-700 !important;
  border: 1px solid $gray-400 !important;
  border-radius: 0.25rem !important;
}

// body[theme="dark"] {
//   .ant-picker {
//     background-color: #2a3042;
//     color: #a6b0cf !important;
//     border-color: tint-color(#2a3042, 10%);
//   }
// }

// body[theme="dark"] {
//   .ant-select-selector {
//     background-color: transparent !important;
//     color: $gray-400 !important;
//   }

//   .ant-select-multiple .ant-select-selection-item {
//     color: $primary !important;
//     background-color: rgba($primary, 0.18);
//     border: none !important;
//   }

//   .ant-picker {
//     background-color: transparent !important;
//     color: $gray-400 !important;
//   }

//   .ant-picker-input > input {
//     color: $gray-400 !important;
//   }
// }

.navbar-custom {
  width: 400px;
  padding: 10px 25px 10px 25px;
}
@media (max-width: 576px) {
  .navbar-custom {
    width: 50vh;
    // display: block;
    // justify-content: flex-start;
  }
}
