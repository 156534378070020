//
// accordion.scss
//

.accordion-item {
  .accordion-zeropadding {
    .accordion-body {
      padding: 0px !important;
    }
  }
}

.custom-accordion {
  .accordion-list {
    display: flex;
    border-radius: 7px;
    background-color: $gray-300;
    padding: 12px 20px;
    color: $body-color;
    font-weight: $font-weight-semibold;
    align-items: center;
    justify-content: space-between;
    &.collapsed {
      i.accor-plus-icon {
        &:before {
          content: "\F0415";
        }
      }
    }

    .accor-plus-icon {
      display: inline-block;
      font-size: 16px;
      height: 24px;
      width: 24px;
      line-height: 22px;
      background-color: $card-bg;
      text-align: center;
      border-radius: 50%;
    }
  }

  a {
    &.collapsed {
      i.accor-down-icon {
        &:before {
          content: "\F0140";
        }
      }
    }
  }

  .card-body {
    color: $text-muted;
  }
}

.accordion-button {
  color: $accordion-button-color;
}

// .collapse-horizontal {
//   transition: 5s ease;
// }

// .collapse-child {
//   width: auto;
// }
#sidebar {
  transition: width 2s ease;
  display: block;
  //   width: 0;
}
#sidebar.collapse-text {
  width: auto;
}

.test {
  display: inline;
}
.test .testtext {
  //   visibility: hidden;
  width: 0px;
  overflow: n;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: width 0.4s ease-in-out;
}
.test:hover .testtext {
  //   visibility: visible;
  opacity: 1;
  width: 20px;
}
