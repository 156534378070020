// /* Custom input field with icon login page */
// // !! Modified
/*.main-content {
  position: relative;
  //   height: 100%;
  min-height: 100vh;
}*/

.app-input {
  // padding: calc(#{$header-height - 28px} / 2) 0;
  .form-control {
    // border: none;
    height: 38px;
    // border-color: #2a304207;
    padding-left: 40px;
    padding-right: 20px;
    background-color: #2a304200;
    // box-shadow: 0 0 7px 7px #2a304202;
    border-radius: 5px;
  }
  span {
    position: absolute;
    z-index: 10;
    font-size: 16px;
    line-height: 38px;
    left: 13px;
    top: 0;
    color: $gray-600;
  }
}

.app-input2 {
  // padding: calc(#{$header-height - 28px} / 2) 0;
  .form-control {
    // border: none;
    height: 30px;
    // border-color: #2a304207;
    padding-left: 40px;
    padding-right: 20px;
    background-color: #2a304200;
    // box-shadow: 0 0 7px 7px #2a304202;
    border-radius: 5px;
  }
  span {
    position: absolute;
    z-index: 10;
    font-size: 16px;
    line-height: 38px;
    left: 13px;
    top: 0;
    color: $gray-600;
  }
}

// body[theme="dark"] {
//   .list-group-item {
//     background-color: $list-group-bg-dark;
//     border-color: $list-group-border-color-dark;
//   }
// }

// Custom
.cursor-pointer {
  cursor: pointer;
}
